// eslint-disable-next-line
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Collapse, { Panel } from "rc-collapse";
import ScrollMenu from "./scrollmenu";
import HighlightSection from "./highlightsection";
import Team from "./team";
import GiftText from "./gift-text"

import debounce from "../../js/debounce";

import "rc-collapse/assets/index.css";
import { getImageUrl } from "takeshape-routing";

const ScrollSection = ({title, selectClients, principles, services, team}) => {
  const [stateData, setStateValues] = useState({
    activeItem: 0,
    menuClass: "wrapper-menu",
    menuBaseClass: "wrapper-menu",
    covering: 0
  });

  const firstSection = useRef();
  const secondSection = useRef();
  const thirdSection = useRef();
  const fourthSection = useRef();
  const animateSection = useRef();
  const scrollMenuRef = useRef();

  const scrollDetect = () => {
    const unsibscribe = () => {
      window.removeEventListener("scroll", detect);
      window.removeEventListener("scroll", revise);
    };

    let scrollContainer = document.getElementById("scroll-container");
    let containerTopPrevPosition = scrollContainer.getBoundingClientRect().top;
    let fixed = false;
    const scrollMenuInitTop = 0;
    const scrollMenuPadding = -10;

    const animateContainer = animateSection.current.animationContentRef.current;
    const animateContainerPaddingTop = parseInt(
      window
        .getComputedStyle(animateContainer, null)
        .getPropertyValue("padding-top")
    );
    let scrollMenu = document.getElementById("scroll-menu");
    scrollMenu.style.marginTop = animateContainerPaddingTop + "px";
    scrollMenu.style.paddingTop = "0";

    const detect = e => {
      if (
        !firstSection.current ||
        !secondSection.current ||
        !thirdSection.current ||
        !fourthSection.current
      )
        return;

      let firstSectionRect = firstSection.current.getBoundingClientRect();
      let secondSectionRect = secondSection.current.getBoundingClientRect();
      let thirdSectionRect = thirdSection.current.getBoundingClientRect();
      let fourthSectionRect = fourthSection.current.getBoundingClientRect();

      let scrollMenuRect = scrollMenu.getBoundingClientRect();
      let limit = scrollMenuRect.bottom;
      let topLimit = scrollMenuRect.top - animateContainerPaddingTop;
      let containerTopLimit = scrollContainer.getBoundingClientRect().top;
      let containerBottomLimit = scrollContainer.getBoundingClientRect().bottom;
      let isDownMove = containerTopLimit < containerTopPrevPosition;

      const getMenuSectionPos = () => {
        const data = {
          menuClass: "wrapper-menu",
          menuBaseClass: "wrapper-menu",
          covering: 100
        };
        /** Fourth section **/
        if (fourthSectionRect.top < limit) {
          let diff = fourthSectionRect.top - scrollMenuRect.top;
          let relativeCovering =
            100 - Math.round((diff / scrollMenuRect.height) * 100);
          if (relativeCovering > 0) {
            data.activeItem = relativeCovering >= 50 ? 4 : 3;
            data.menuClass = "wrapper-menu";
            data.menuBaseClass = "wrapper-menu";
            data.covering = relativeCovering;
            return data;
          }
        }
        /** Fourth section end **/
        /** Third section **/
        if (thirdSectionRect.top < limit) {
          let diff = thirdSectionRect.top - scrollMenuRect.top;
          let relativeCovering =
            100 - Math.round((diff / scrollMenuRect.height) * 100);
          data.menuClass = "wrapper-menu";
          data.menuBaseClass = "wrapper-menu-dark";
          data.covering = relativeCovering;
          if (relativeCovering >= 50) {
          }
          data.activeItem = relativeCovering >= 50 ? 3 : 2;
          return data;
        }
        /** Third section end  **/
        /** Second section **/
        if (secondSectionRect.top < limit) {
          let diff = secondSectionRect.top - scrollMenuRect.top;
          let relativeCovering =
            100 - Math.round((diff / scrollMenuRect.height) * 100);

          data.activeItem = relativeCovering >= 50 ? 2 : 1;
          data.menuClass = "wrapper-menu-dark";
          data.covering = relativeCovering;
          return data;
        }
        /** Second section end **/
        /** First section **/
        if (firstSectionRect.top < limit) {
          data.activeItem = 1;
          return data;
        }
        /** First section end **/
      };

      if (
        !isDownMove &&
        containerTopLimit >= scrollMenuInitTop - scrollMenuPadding &&
        fixed &&
        containerBottomLimit + scrollMenuPadding > limit
      ) {
        scrollMenu.style.top = "";
        scrollMenu.style.bottom = "";
        scrollMenu.style.position = "";
        fixed = false;
      }

      if (
        isDownMove &&
        topLimit <= scrollMenuInitTop &&
        !fixed &&
        containerBottomLimit + scrollMenuPadding > limit
      ) {
        scrollMenu.style.top = "0";
        scrollMenu.style.bottom = "";
        scrollMenu.style.position = "fixed";
        fixed = true;
      }

      if (isDownMove && containerBottomLimit <= limit && fixed) {
        scrollMenu.style.top = "";
        scrollMenu.style.bottom = "0";
        scrollMenu.style.position = "";
        fixed = false;
      }

      if (
        !isDownMove &&
        scrollMenu.style.bottom === "0px" &&
        scrollMenuInitTop + scrollMenuRect.height < containerBottomLimit
      ) {
        scrollMenu.style.top = "0";
        scrollMenu.style.bottom = "";
        scrollMenu.style.position = "fixed";
        fixed = true;
      }

      containerTopPrevPosition = containerTopLimit;

      let pos = getMenuSectionPos();
      setStateValues({ ...stateData, ...pos });

      return () => unsibscribe();
    };

    const revise = debounce(() => {
      detect();
    }, 150);

    window.addEventListener("scroll", detect);
    window.addEventListener("scroll", revise);
    return () => unsibscribe();
  };

  useEffect(() => scrollDetect(), []);

  return (
    <>
      <div
        id="scroll-container"
        ref={scrollMenuRef}
        className="container container--fluid container--bordered"
      >
        <ScrollMenu
          activeItem={stateData.activeItem}
          menuClass={stateData.menuClass}
          baseClass={stateData.menuBaseClass}
          covering={stateData.covering}
        />
        <div
          className="section section-columns gs-animate"
          id="section1"
          ref={firstSection}
        >
          <div className="row">
            <div className="row__column width-25">
              <h1
                className="indent-text indent-text--desktop desktop-hide"
                itemProp="name"
              >
                {title}
              </h1>
            </div>
            <HighlightSection
              ref={animateSection}
              title={
                <>
                  <h3 className="inline-scroll-heading desktop-hide">
                    01. Principles
                  </h3>
                </>
              }
              content={
                <>
                  {principles.description}
                </>
              }
            >
              <Collapse accordion={true}>
                {principles.panels.map((panel, idx) =>
                  <Panel
                    header={panel.title}
                    headerClass="h1"
                    forceRender={true}
                    key={idx}
                  >
                    <GiftText
                      description={panel.description}
                      gift = {panel.gift} />
                  </Panel>
                )}
              </Collapse>
            </HighlightSection>
          </div>
        </div>
        <div
          className="section gs-animate dark-section brand-section flex-item flex-item--center"
          id="section2"
          ref={secondSection}
        >
          <h3 className="inline-scroll-heading desktop-hide mob-absolute">
            02. Clients
          </h3>
          <ul
            className="brand-list listreset"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            {selectClients.map((img, idx) =>
              <li
                className="brand-list__item flex-item flex-item--center"
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ImageObject"
                key={idx}
              >
                <img
                  src={getImageUrl(img.asset.path, {auto:"compress", fit: "crop"})}
                  alt={img.asset.title || ''}
                  itemProp="contentUrl"
                />
              </li>
            )}
          </ul>
        </div>
        <div
          className="section gs-animate section-columns"
          id="section3"
          ref={thirdSection}
        >
          <div className="row">
            <div className="row__column width-25"></div>
            <HighlightSection
              title={
                <>
                  <h3 className="inline-scroll-heading desktop-hide">
                    03. Services
                  </h3>
                  {/*<Link to="/services" tabIndex="0" className="arrow-link">Read More</Link>*/}
                </>
              }
              content={
                <>
                 {services.description}
                </>
              }
            >
              <ul className="services-list listreset">
                {services.panels.map((panel, idx) =>
                  <li className="services-list__item" key={idx}>
                    <h3>{panel.title}</h3>
                    <GiftText
                      className="secondary"
                      description={panel.description}
                      gift = {panel.gift} />
                  </li>
                )}
              </ul>
            </HighlightSection>
          </div>
        </div>
        <div
          className="section gs-animate section-columns team-section box box--top-bordered"
          id="section4"
          ref={fourthSection}
        >
          <div className="row">
            <div className="row__column width-25 team-section__heading">
              <h3 className="inline-scroll-heading desktop-hide">04. Team</h3>
            </div>
            <div className="row__column row__column--no-padding width-75">
              <Team team={team}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollSection;
