import React from "react";

class HighlightSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { gif: null, nodes: [] };
    this.animationContentRef = React.createRef();
    this.contentRef = React.createRef();
    this.contentParagraphRef = React.createRef();
  }

  showGif(e, gif) {
    const offset = 80; // approximate gif height
    let paragraph = this.contentParagraphRef.current;

    const bottom = Math.abs(
      this.animationContentRef.current.getBoundingClientRect().bottom -
        e.target.getBoundingClientRect().bottom
    );
    const imgStyle = {};
    if (
      e.target.getBoundingClientRect().bottom - offset >=
      paragraph.getBoundingClientRect().bottom
    ) {
      imgStyle.bottom = bottom + "px";
    }
    let gifImg = <img src={gif} width={130} style={imgStyle} alt="" />;
    this.setState({ gif: gifImg });
  }

  hideGif() {
    this.setState({ gif: null });
  }

  showGifHandler(gif, e) {
    this.showGif(e, gif);
  }

  componentDidMount() {
    const nodes = this.contentRef.current.querySelectorAll("span.highlight");
    this.setState({ nodes: nodes });
    nodes.forEach(element => {
      let gif = element.dataset.gif;
      element.addEventListener(
        "mouseover",
        this.showGifHandler.bind(this, gif)
      );
      element.addEventListener("mouseout", this.hideGif.bind(this));
    });
  }

  componentWillUnmount() {
    this.state.nodes.forEach(element => {
      element.removeEventListener("mouseover", this.showGifHandler);
      element.removeEventListener("mouseout", this.hideGif);
    });
  }

  render() {
    return (
      <>
        <div
          ref={this.animationContentRef}
          className="row__column width-25 animation-content"
        >
          {this.props.title}
          <p data-paragraph ref={this.contentParagraphRef}>
            {this.props.content}
          </p>
          {this.state.gif}
        </div>
        <div
          ref={this.contentRef}
          className="row__column width-50 highlight-section"
        >
          {this.props.children}
        </div>
      </>
    );
  }
}

export default HighlightSection;
