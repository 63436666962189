import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const AboutTitleModule = ({ title, description }) => {
  const data = useStaticQuery(graphql`
    query {
      Image18: file(relativePath: { eq: "img18.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED, width: 2048)
        }
      }
    }
  `);

  return (
    <section className="section gs-animate title-section">
      <div className="container container--bordered container--fluid">
        <div className="brand-img__wrap">
          <div
            className="brand-img__image box padded box--top-bordered box--bottom-bordered long-box"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <GatsbyImage
              image={data.Image18.childImageSharp.gatsbyImageData}
              alt="Image description"
              itemProp="contentUrl"
            />
          </div>
        </div>
      </div>
      <div className="container container--bordered">
        <div className="box heading">
          <h2 className="h1 indent-text complex-heading footer-heading">
            <sup>{title}</sup>
            {description}
            <Link to="/careers">See open positions</Link>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default AboutTitleModule;
