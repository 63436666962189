import React from "react";

import Tabs from "../tabs/tabs";
import LazyImage from "../lazyImage";
import Title from "../heroTitle";
import { getImageUrl } from "takeshape-routing";

const AboutBannerSection = ({title, offices}) => {
  
  return (
    <div className="section hero-section-wrap about-banner">
      <div className="about-banner__content">
        <div className="holder">
          <Title className="indent-text indent-text--desktop" itemProp="name">
            {title}
          </Title>
        </div>
      </div>
      <Tabs>
        {offices.map(office => 
          <div title={office.city} key={office.city}>
            <LazyImage src={getImageUrl(office.banner.path, {auto:"compress", fit: "crop"})} Tag="section" />
          </div>
        )}        
      </Tabs>
    </div>
  );
};

export default AboutBannerSection;
