import React from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";

import ScrollSection from "../components/aboutpage/scrollsection";
import Layout from "../components/layout";
import Seo from "../components/seo";
import AboutTitleModule from "../components/aboutpage/about-title-module";
import AboutBanner from "../components/aboutpage/about-banner";

class AboutPage extends React.Component {
  render() {
    const { about } = this.props.data.takeshape;
    if (
      (!about.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
      (!about.enableOnStaging && process.env.GATSBY_ENVIRONMENT === "staging")
    ) {
      if (typeof window !== "undefined") {
        navigate("/");
      }
    }

    return (
      <Layout>
        <Seo title={about.pageTitle} description={about.pageDescription} />
        <div className="main--wrap">
          <AboutBanner title={about.title} offices={about.offices} />
          <ScrollSection {...about} handleScroll={this.handleScroll} />
          <AboutTitleModule
            title={about.missionTitle}
            description={about.missionDescription}
          ></AboutTitleModule>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      about: getAbout {
        pageTitle
        pageDescription
        enabled
        enableOnStaging
        title
        offices {
          banner {
            path
            title
          }
          city
        }
        missionDescription
        missionTitle
        principles {
          description
          panels {
            description
            title
            gift {
              image {
                path
                title
              }
              offset
              text
            }
          }
        }
        selectClients {
          asset {
            path
            title
          }
        }
        services {
          description
          panels {
            description
            title
            gift {
              image {
                path
                title
              }
              offset
              text
            }
          }
        }
        team {
          avatar {
            path
            title
          }
          name
          role {
            name
          }
        }
      }
    }
  }
`;
