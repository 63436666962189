import React from "react";
import { Link as ScrollLink } from "react-scroll";

const ScrollMenu = ({ activeItem, menuClass, baseClass, covering }) => {
  const fistItemClass = activeItem === 1 ? "active" : "";
  const secondItemClass = activeItem === 2 ? "active" : "";
  const thirdItemClass = activeItem === 3 ? "active" : "";
  const fourthItemClass = activeItem === 4 ? "active" : "";
  if (covering > 90) {
    baseClass = menuClass;
  }
  return (
    <ul id="scroll-menu" itemScope itemType="http://schema.org/ItemList">
      <li
        className={covering > 89 ? menuClass : baseClass}
        itemProp="itemListElement"
      >
        <ScrollLink
          className={fistItemClass}
          to="section1"
          smooth={true}
          offset={0}
          duration={500}
          isDynamic={true}
        >
          01. Principles
        </ScrollLink>
      </li>
      <li
        className={covering > 70 ? menuClass : baseClass}
        itemProp="itemListElement"
      >
        <ScrollLink
          className={secondItemClass}
          to="section2"
          smooth={true}
          offset={0}
          duration={500}
          isDynamic={true}
        >
          02. Select Clients
        </ScrollLink>
      </li>
      <li
        className={covering > 45 ? menuClass : baseClass}
        itemProp="itemListElement"
      >
        <ScrollLink
          className={thirdItemClass}
          to="section3"
          smooth={true}
          offset={0}
          duration={500}
          isDynamic={true}
        >
          03. Services
        </ScrollLink>
      </li>
      <li
        className={covering > 20 ? menuClass : baseClass}
        itemProp="itemListElement"
      >
        <ScrollLink
          className={fourthItemClass}
          to="section4"
          smooth={true}
          offset={0}
          duration={500}
          isDynamic={true}
        >
          04. Team
        </ScrollLink>
      </li>
    </ul>
  );
};

export default ScrollMenu;
