import React, { Component } from "react";
import PropTypes from "prop-types";

class Tab extends Component {
  onClick = () => {
    const { title, onClick } = this.props;
    onClick(title);
  };

  render() {
    const {
      onClick,
      props: { activeTab, title }
    } = this;

    let className = "tab-list-item";

    if (activeTab === title) {
      className += " tab-list-active";
    }

    return (
      <li className={className} onClick={onClick} role="presentation" >
        {title}
      </li>
    );
  }
}

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Tab;
