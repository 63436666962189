import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

class Teampart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isHovered: false };
    this.mouseOver.bind(this);
    this.mouseOut.bind(this);
  }

  mouseOver() {
    this.setState({ isHovered: true });
  }

  mouseOut() {
    this.setState({ isHovered: false });
  }

  componentDidMount() {
    document.documentElement.setAttribute("data-agent", navigator.userAgent);
  }

  render() {
    return (
      <div
        className="team__member"
        onMouseLeave={() => this.mouseOut()}
        onMouseEnter={() => this.mouseOver()}
        role="presentation"
        itemScope
        itemType="http://schema.org/Person"
      >
        {this.props.src ? (
          <div
            className="blur"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <div aria-hidden="true" className="hidden-div"></div>
            <img src={this.props.src} alt={this.props.name} itemProp="image" />
          </div>
        ) : (
          <div
            className="blur"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <GatsbyImage
              image={this.props.gatsbyImageData}
              alt={this.props.name}
              itemProp="Image"
            />
          </div>
        )}

        <svg className="blur-ie">
          <defs>
            <filter id="blur">
              <feGaussianBlur stdDeviation="20" />
            </filter>
          </defs>
          <image
            xlinkHref={this.props.blurSrc}
            width="100%"
            height="100%"
            filter="url(#blur)"
            alt="Image description"
            itemProp="contentUrl"
          ></image>
        </svg>
        <div className="caption">
          <span className="name" itemProp="name">
            {this.props.name}
          </span>
          <span className="position" itemProp="jobTitle">
            {this.props.position &&
              this.props.position.map((role) => role.name).join(", ")}
          </span>
        </div>
      </div>
    );
  }
}

export default Teampart;
