import React from "react";
import reactStringReplace from 'react-string-replace';

const GiftText = ({className, description, gift}) => {
  return (
    <p className={className}>
      {reactStringReplace(description, '%gift%', (match, i) => (
        <span
          className="highlight"
          data-gif={"https://images.takeshape.io/" + gift.image.path}
          data-offset={gift.image.offset}
          key={i}
        >
          {gift.text}
        </span>
      ))}
    </p>
  )
}
export default GiftText;