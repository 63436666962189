import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImageUrl } from "takeshape-routing";
import Teampart from "./teampart";

import teamImg from "../../assets/images/team-pic01.jpg";

const Team = ({ team }) => {
  const data = useStaticQuery(graphql`
    query {
      blurSrc: file(relativePath: { eq: "team-pic01.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 75, layout: CONSTRAINED, width: 300)
        }
      }
    }
  `);
  return (
    <>
      <div className="team">
        {team.map((member, idx) => (
          <div className="team__wrapper" data-title="" key={idx}>
            <Teampart
              src={getImageUrl(member.avatar.path, {
                auto: "compress",
                fit: "crop",
              })}
              blurSrc={teamImg}
              name={member.name}
              position={member.role}
            />
          </div>
        ))}

        <div className="team__wrapper">
          <Teampart
            gatsbyImageData={data.blurSrc.childImageSharp.gatsbyImageData}
            blurSrc={teamImg}
            name=""
            position=""
          />
          <div className="team__contact">
            <div className="content">
              <span>Want to join us?</span>
              <a
                href="mailto:&#105;&#110;&#102;&#111;&#064;&#104;&#097;&#108;&#102;&#104;&#101;&#108;&#105;&#120;&#046;&#099;&#111;&#109;"
                tabIndex="0"
                className="arrow-link alt-color"
              >
                Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
