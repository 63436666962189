import React, { Component } from "react";
import PropTypes from "prop-types";

import Tab from "./tabs-item";
import panoramaIcon from "../../assets/images/panorama/360-view.png";

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.title,
      disableClass: false
    };
  }

  handleAddActiveTab = tab => {
    this.setState({ activeTab: tab });
  };

  handleAddDisableClass = () => {
    this.setState(prevState => ({ disableClass: !prevState.disableClass }));
  };
  handleClick = e => {

  }
  render() {
    const { activeTab, disableClass } = this.state;

    const { children } = this.props;

    return (
      <div className={`tabs ${disableClass ? "" : "disabled-panorama"}`}>
        <div className="badge" onClick={this.handleAddDisableClass} onKeyDown={this.handleClick} role="button" tabIndex="0">
          <img src={panoramaIcon} alt="360 View" title="360 View" />
        </div>
        <div className="tabs-list-holder">
          <span className="list-label">Office View:</span>
          <ol className="tab-list">
            {children.map(child => {
              const { title } = child.props;

              return (
                <Tab
                  activeTab={activeTab}
                  key={title}
                  title={title}
                  onClick={this.handleAddActiveTab}
                />
              );
            })}
          </ol>
        </div>
        <div className="tab-content">
          {children.map(child => {
            let className = "tab-content-item";

            if (child.props.title === activeTab) {
              className += " tab-content-item--active";
            }

            return (
              <div className={className} key={child.props.title}>
                {child.props.children}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};

export default Tabs;
